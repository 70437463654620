<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div class="text-left">
              <button
                type="button"
                data-toggle="modal"
                data-target="#addModal"
                class="btn btn-success ml-auto float-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user-plus"
                >
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="8.5" cy="7" r="4"></circle>
                  <line x1="20" y1="8" x2="20" y2="14"></line>
                  <line x1="23" y1="11" x2="17" y2="11"></line>
                </svg>
              </button>
            </div>

            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive">
              <table
                id="BUSINESS_DEV"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Contact Email</th>
                    <th>Contact Phone</th>
                    <th>Type</th>
                    <th>Location</th>
                    <th>Note</th>
                    <th>Added on</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lead in leads" :key="lead.id">
                    <td>{{ lead.name }}</td>
                    <td>{{ lead.email }}</td>
                    <td>{{ lead.phone }}</td>
                    <td>{{ lead.type.toUpperCase() }}</td>
                    <td>{{ lead.location }}</td>
                    <td>{{ lead.note }}</td>
                    <td>{{ formatDate(lead.created_at) }}</td>
                    <td class="d-flex">
                      <router-link :to="`/businessdevelopment/logs/${lead.id}`">
                        <div class="text-center">
                          <b-button variant="outline-success" class="d-flex">
                            <i class="fa fa-comment"></i>
                            <b-badge variant="light">{{
                              lead.logs.length
                            }}</b-badge>
                          </b-button>
                        </div>
                      </router-link>
                      <button
                        @click.prevent="showEdit(lead)"
                        class="btn btn-sm m-1 btn-outline-warning"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        @click.prevent="showDelete(lead.id)"
                        class="btn btn-sm m-1 btn-outline-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div
      class="modal fade"
      id="addModal"
      role="dialog"
      aria-labelledby="addModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Add New Lead</p>
                </div>
                <form id="addModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalName"> Name</label>
                        <input
                          type="text"
                          id="addModalName"
                          class="form-control"
                          v-model="newLead.name"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalEmail"
                          >Contact Email (optional)</label
                        >
                        <input
                          type="email"
                          id="addModalEmail"
                          class="form-control"
                          v-model="newLead.email"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalPhone">Contact Phone </label>
                        <input
                          type="number"
                          id="addModalPhone"
                          class="form-control"
                          v-model="newLead.phone"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalType">Type </label>
                        <select
                          class="form-control"
                          id="addModalType"
                          v-model="newLead.type"
                        >
                          <option value="church">Church</option>
                          <option value="government">Government</option>
                          <option value="ngo">NGO</option>
                          <option value="company">Company</option>
                          <option value="hmo">HMO</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalLocation"
                          >Location (optional)
                        </label>
                        <input
                          type="text"
                          id="addModalLocation"
                          class="form-control"
                          v-model="newLead.location"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalNote">Note (optional)</label>
                        <textarea
                          class="form-control"
                          id="addModalNote"
                          rows="3"
                          v-model="newLead.note"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveNewLead()"
              class="btn btn-success"
              :disabled="loadingNew"
            >
              <b-spinner small v-if="loadingNew"></b-spinner>
              <span v-else>Save <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      role="dialog"
      aria-labelledby="editModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Edit Lead Information</p>
                </div>
                <form id="editModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalName">Church Name</label>
                        <input
                          type="text"
                          id="editModalName"
                          class="form-control"
                          v-model="editLead.name"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalEmail"
                          >Contact Email (optional)</label
                        >
                        <input
                          type="email"
                          id="editModalEmail"
                          class="form-control"
                          v-model="editLead.email"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalPhone"
                          >Contact Phone Number
                        </label>
                        <input
                          type="text"
                          id="editModalPhone"
                          class="form-control"
                          v-model="editLead.phone"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalType">Type </label>
                        <select
                          class="form-control"
                          id="addModalType"
                          v-model="editLead.type"
                        >
                          <option value="church">Church</option>
                          <option value="government">Government</option>
                          <option value="ngo">NGO</option>
                          <option value="company">Company</option>
                          <option value="hmo">HMO</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalLocation"
                          >Location (optional)
                        </label>
                        <input
                          type="text"
                          id="editModalLocation"
                          class="form-control"
                          v-model="editLead.location"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalNote">Note (optional)</label>
                        <textarea
                          class="form-control"
                          id="editModalNote"
                          rows="3"
                          v-model="editLead.note"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveEditLead()"
              class="btn btn-success"
              :disabled="loadingEdit"
            >
              <b-spinner small v-if="loadingEdit"></b-spinner>
              <span v-else>Save Changes <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete  -->
    <div
      class="modal fade"
      id="deleteModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-danger">
                    Are you sure you want to delete this Lead?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Cancel</button>

            <button
              @click.prevent="completeDelete()"
              class="btn btn-danger text-white"
              :disabled="loadingDelete"
            >
              <b-spinner small v-if="loadingDelete"></b-spinner>
              <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { Lead } from "@/models/lead.js";

export default {
  name: "BusinessDevelopment",
  components: {},

  data() {
    return {
      loading: true,
      leads: [],

      loadingNew: false,
      newLead: new Lead(),

      visitsToShow: [],

      loadingEdit: false,
      editLead: {},

      loadingDelete: false,
      deleteLead: {},
    };
  },

  mounted() {
    this.fetchLeads();
  },

  methods: {
    initTable() {
      var dt = $("#BUSINESS_DEV").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([4, 5]).visible(false);
    },

    fetchLeads() {
      this.loading = true;
      http
        .get(endpoints.ALL_LEADS)
        .then((response) => {
          this.loading = false;
          this.leads = response;
          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    saveNewLead() {
      if (
        this.newLead.name == "" ||
        this.newLead.phone == "" ||
        this.newLead.type == ""
      ) {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingNew = true;
        http
          .post(endpoints.NEW_LEAD, {
            ...this.newLead,
          })
          .then((response) => {
            this.loadingNew = false;
            this.$toast.success(response);
            $("#addModal").modal("hide");
            this.newLead = new Lead();
            this.fetchLeads();
          })
          .catch((error) => {
            this.loadingNew = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
          });
      }
    },

    showVisits(x) {
      this.visitsToShow = x;
      $("#visitsModal").modal("show");
    },

    showEdit(x) {
      this.editLead = x;
      $("#editModal").modal("show");
    },

    saveEditLead() {
      if (this.editLead.name == "" || this.editLead.phone == "") {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingEdit = true;
        http
          .put(endpoints.EDIT_LEAD, {
            ...this.editLead,
          })
          .then((response) => {
            this.loadingEdit = false;
            this.$toast.success(response);
            $("#editModal").modal("hide");
            this.editLead = new Lead();
            this.fetchLeads();
          })
          .catch((error) => {
            this.loadingEdit = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
          });
      }
    },

    showDelete(x) {
      this.deleteLead = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(endpoints.DELETE_LEAD.replace(":id", this.deleteLead))
        .then((response) => {
          console.log(response);
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.$toast.success(response);
          this.fetchLeads();
        })
        .catch((error) => {
          console.log(error);
          this.loadingDelete = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },
  },
};
</script>
