export class Lead {
  constructor() {
    (this.name = ""),
      (this.email = ""),
      (this.phone = ""),
      (this.type = ""),
      (this.location = ""),
      (this.note = "");
  }
}
